import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MenuModalComponent} from '../menu-modal/menu-modal.component';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';
import {ModalController} from '@ionic/angular';

@Component({
	selector: 'app-header-with-language-switcher',
	templateUrl: './header-with-language-switcher.component.html',
	styleUrls: ['header-with-language-switcher.component.scss']
})
export class HeaderWithLanguageSwitcherComponent implements OnInit {
	@Input() logo = false;
	@Input() backButton = false;
	@Input() burgerButton = false;
	@Input() crossButton = false;
	@Input() title = '';

	@Input() showUserIcon = false;
	@Input() isAuthorized = false;

	@Output() backClick = new EventEmitter<void>();
	@Output() close = new EventEmitter<void>();

	visibleMenuModal = false;

	constructor(
		public translate: TranslateService,
		private modalCtrl: ModalController
	) {}

	ngOnInit() {}

	async presentPopoverMenuModal() {
		const popover = await this.modalCtrl.create({
			component: MenuModalComponent,
			backdropDismiss: true,
			showBackdrop: true,
			cssClass: 'menu-modal'
		});
		popover.onWillDismiss().then(() => {
			this.visibleMenuModal = false;
		});
		this.visibleMenuModal = true;
		await sleep(200);
		await popover.present();
		return;
	}
}
