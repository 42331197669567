import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const sevenbybatCustomerGroup = 'seven_by_bat';
export const customerGroup = sevenbybatCustomerGroup;

const supportEmail = {
	seven_by_bat: 'sevenbybat@smoothr.de'
};
const firebaseConfig = {
	seven_by_bat: {
		apiKey: 'AIzaSyB8BpDVq-H7vDizkQNNiGHhIUA1zhYMr-E',
		authDomain: 'sevenbybat.firebaseapp.com',
		projectId: 'sevenbybat',
		storageBucket: 'sevenbybat.appspot.com',
		messagingSenderId: '683666892262',
		appId: '1:683666892262:web:977f71c42641867d44a227',
		vapidKey: 'BHp2xr83SElA7Lp6riGMazrW682A-rQedSCZeyzEHx_iJ3sKlM0XNIB7A04ouCh-MstEggCKthXTonNn2tUsJM0'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyB1zuV-fVlLlytAWtsVdSapFLRkuETwcyg',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	hasRecommendations: false,
	venueId: '661690a1a275681183532ec3',
	payPalValidatePrice: false,
	payPalMinPrice: 0,
	showDiffrenceModal: false,
	showOrderPriceFromBackend: false,
	getPriceByUser: true
};
