import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import Payment from 'src/smoothr-web-app-core/models/Payment';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Order from 'src/smoothr-web-app-core/models/Order';
import {PaymentMethod} from 'src/smoothr-web-app-core/enums/PaymentMethod';
import {environment} from 'src/environments/environment';
import {Api, PAYMENT_SERVICE} from 'src/smoothr-web-app-core/api/api';
import {OrderUtils} from 'src/smoothr-web-app-core/utils/order-utils';
import {AnalyticsService} from 'src/smoothr-web-app-core/services/analytics/analytics.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import {paymentFromOrders, sleep} from 'src/smoothr-web-app-core/utils/utils';
import {ModalController} from '@ionic/angular';
import {PaymentModalResult} from 'src/smoothr-web-app-core/components/payment-modal/payment-modal.component';
import {paymentBody} from 'src/smoothr-web-app-core/models/Converter';

// tslint:disable-next-line:prefer-const
declare var wpwlOptions: any;
// @ts-ignore
const applePayAvailable = !!window.ApplePaySession;

@Component({
	selector: 'app-payment',
	templateUrl: './payment.page.html',
	styleUrls: ['payment.page.scss']
})
export class PaymentPage implements OnInit, OnDestroy {
	static url = 'payment';
	payment: Payment;
	venue: Venue;
	orders: Order[] = [];
	loading = false;
	paymentMethods: PaymentMethod[] = [];
	selectedPaymentMethod: PaymentMethod = null;
	pm = PaymentMethod;
	vrUrl = environment.baseUrl + PAYMENT_SERVICE + 'vr/pay/';
	orderUtils = OrderUtils;
	environment = environment;
	payments = {};
	wpwlOptionsBase = {
		locale: 'de',
		style: 'plain'
	};
	constructor(
		public repository: RepositoryService,
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private analytics: AnalyticsService,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService,
		private modalCtrl: ModalController
	) {}
	ngOnInit(): void {
		this.analytics.paymentModalOpened();

		this.route.queryParams.subscribe(params => {
			console.log(params);
			if (params?.venueId && params?.orderId) {
				this.checkPaymentMethods(params?.venueId, params?.orderId);
			}
		});
	}
	private async checkPaymentMethods(venueId: string, orderId: string) {
		if (!venueId) {
			return;
		}
		try {
			const venue = (await Api.getLazyVenue(venueId)).data;
			this.venue = venue;
			if (venue.ccEnabled) {
				this.paymentMethods.push(PaymentMethod.CREDIT_CARD);
			}
			if (venue.gpEnabled) {
				this.paymentMethods.push(PaymentMethod.GPAY);
			}
			if (venue.apEnabled && applePayAvailable) {
				this.paymentMethods.push(PaymentMethod.APAY);
			}
			if (venue.ppEnabled) {
				this.paymentMethods.push(PaymentMethod.PAYPAL);
			}
			if (venue.sbEnabled) {
				this.paymentMethods.push(PaymentMethod.SOFORTBANKING);
			}
			if (venue.worldlineEnabled) {
				this.paymentMethods.push(PaymentMethod.WORLDLINE);
			}
			if (venue.sepaMerchantId && venue.sepaPreorderEnabled) {
				this.paymentMethods.push(PaymentMethod.VR_SEPA);
			}
			this.orders.push((await Api.getOrder(orderId)).data);
			this.payment = paymentFromOrders(this.orders, 0);
			console.log(this.payment);
		} catch (e) {
			console.log(e);
		}
	}
	static async navigate(router: Router) {
		await router.navigateByUrl(PaymentPage.url);
	}
	ngOnDestroy(): void {}

	async loadPayment() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.resetJs();
		this.payment._id = null;
		this.payment.method = this.selectedPaymentMethod;
		// reset payment container
		this.selectedPaymentMethod = null;
		wpwlOptions = null;
		this.cdr.detectChanges();
		this.selectedPaymentMethod = this.payment.method;

		try {
			switch (this.selectedPaymentMethod) {
				case PaymentMethod.PAYPAL:
					await this.loadPaypal();
					break;
				case PaymentMethod.CREDIT_CARD:
					await this.loadCreditCard();
					break;
				case PaymentMethod.SOFORTBANKING:
					await this.loadKlarna();
					break;
				case PaymentMethod.GPAY:
					await this.loadGpay();
					break;
				case PaymentMethod.APAY:
					await this.loadApay();
					break;
				case PaymentMethod.VR_SEPA:
					await this.loadCreditCard();
					break;
			}
		} catch (e) {
			console.error(e);
			this.snackBarCtrl.open(this.translate.instant('payment_modal.error'));
			this.orders.forEach(order => {
				this.analytics.paymentLoadingFailed(order, this.selectedPaymentMethod);
			});
			this.selectedPaymentMethod = null;
			this.loading = false;
			return false;
		}
		this.loading = false;
		this.orders.forEach(order => {
			this.analytics.paymentLoaded(order, this.selectedPaymentMethod);
		});
	}

	loadVr(paymentMethod: PaymentMethod, paramsBuilder: (payment: Payment) => any): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					console.log({paymentMethod, wpwl: prevPayment.wpwlOptions});
					this.payment = prevPayment;
					wpwlOptions = JSON.parse(JSON.stringify(this.payment.wpwlOptions));
					console.log(wpwlOptions);
					await this.loadVrJs(this.payment.vrPayment.checkoutId);
					return;
				}
				this.payment = await Api.createPayment(this.payment);
				wpwlOptions = {
					...this.wpwlOptionsBase,
					...paramsBuilder(this.payment)
				};
				console.log({paymentMethod, wpwl: wpwlOptions});
				this.payment.wpwlOptions = JSON.parse(JSON.stringify(wpwlOptions));
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));
				await this.loadVrJs(this.payment.vrPayment.checkoutId);
				resolve();
			} catch (e) {
				this.loading = false;

				reject(e);
			}
		});
	}

	async loadCreditCard() {
		if (!this.venue.vrPaymentEnabled && this.venue.ppFullIntegrationEnabled) {
			await this.loadPaypalCreditCard();
		}
		await this.loadVr(PaymentMethod.CREDIT_CARD, payment => ({
			billingAddress: this.getBillingAddress(this.orders[0], this.repository.customer),
			mandatoryBillingFields: {
				country: true,
				state: false,
				city: true,
				postcode: true,
				street1: true
			}
		}));
	}

	async loadGpay() {
		await this.loadVr(PaymentMethod.GPAY, payment => ({
			googlePay: {
				gatewayMerchantId: payment.merchantId,
				allowedAuthMethods: ['PAN_ONLY'],
				allowedCardNetworks: ['MASTERCARD', 'VISA'],
				merchantName: this.venue.name,
				merchantId: environment.GPAY.MERCHANT_ID,
				gateway: environment.GPAY.GATEWAY,
				assuranceDetailsRequired: true
			}
		}));
	}

	async loadApay() {
		await this.loadVr(PaymentMethod.APAY, payment => ({
			applePay: {
				version: 3,
				displayName: this.venue.name,
				total: this.payment.sum,
				currencyCode: this.payment.currency,
				checkAvailability: 'canMakePayments',
				merchantIdentifier: environment.APAY_MERCHANT_ID,
				style: 'black',
				merchantCapabilities: ['supports3DS'],
				supportedNetworks: ['masterCard', 'visa'],
				shippingType: 'storePickup',
				onCancel: () => {
					console.log('onCancel');
					this.loading = false;
				},
				onPaymentAuthorized: applePayment => {
					console.log('onPaymentAuthorized payment: ' + JSON.stringify(applePayment));
					// this.payment.status = PaymentStatus.done;
					// this.orders.forEach(order => {
					// 	this.analytics.purchase(order, this.payment);
					// });
					// this.modalCtrl.dismiss({
					// 	result: PaymentModalResult.SUCCESS,
					// 	payment: this.payment
					// });
				}
			}
		}));
	}

	getBillingAddress(
		order: Order,
		customer: Customer
	): {country: string; city: string; postcode: string; street1: string} {
		if (customer && customer.street) {
			return {
				country: customer.country.toUpperCase(),
				city: customer.city,
				postcode: customer.postalCode,
				street1: customer.street + ' ' + customer.number
			};
		}
		return {
			country: order.preorder?.country?.toUpperCase(),
			city: order.preorder?.city,
			postcode: order.preorder?.postalCode,
			street1: order.preorder?.street + ' ' + order.preorder?.number
		};
	}

	resetJs() {
		const head = document.getElementsByTagName('head')[0];
		const prevVrJs = document.getElementById('vr-js');
		if (prevVrJs) {
			head.removeChild(prevVrJs);
		}
		const prevPpJs = document.getElementById('paypal-js');
		if (prevPpJs) {
			head.removeChild(prevPpJs);
		}
	}

	loadVrJs(checkoutId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			console.log('load: ' + environment.VR_URL + '?checkoutId=' + checkoutId);
			let widget = null;
			while (!widget) {
				this.cdr.detectChanges();
				await sleep(10);
				widget = document.getElementsByClassName('paymentWidgets')[0];
			}
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'vr-js';
			script.src = `${environment.VR_URL}?checkoutId=${checkoutId}`;
			head.appendChild(script);
			script.onerror = () => {
				reject('Could not load script');
				this.loading = false;
			};
			script.onload = async () => {
				this.loading = false;

				resolve();
			};
		});
	}

	loadPaypal(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.PAYPAL, 'paypal-button');
	}

	loadKlarna(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.SOFORTBANKING, 'klarna-button');
	}

	loadPaypalCreditCard(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.CREDIT_CARD, 'paypal-cc-button');
	}

	loadPaypalJs(payment: Payment): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'paypal-js';
			script.src = environment.PAYPAL.JS_URL + payment.currency + '&merchant-id=' + payment.merchantId;
			script.setAttribute('data-partner-attribution-id', environment.PAYPAL.BN_CODE);
			head.appendChild(script);
			script.onerror = () => reject('Could not load script');
			script.onload = () => resolve();
		});
	}

	loadPaypalButton(payment: Payment, fundingSource: any, elementId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			// @ts-ignore
			const button = paypal.Buttons({
				intent: 'capture',
				commit: true,
				vault: false,
				enableStandardCardFields: true,
				createOrder: (data, actions) => {
					return payment.paypal.orderId;
				},
				onApprove: data => {
					this.finishPaypal(payment, data)
						.then(() => {
							resolve();
						})
						.catch(err => {
							reject(err);
						});
				},
				onError: err => {
					this.loading = true;
				},
				fundingSource
			});
			if (!button.isEligible()) {
				reject('Button not eligible');
				return;
			}
			button.render('#' + elementId);
			resolve();
		});
	}

	async finishPaypal(payment: Payment, data: any) {
		this.loading = true;
		try {
			const result = await Api.finishPaypalPayment(data.orderID);
			this.orders.forEach(order => {
				this.analytics.purchase(order, result);
			});
			await this.modalCtrl.dismiss({
				result: PaymentModalResult.SUCCESS,
				payment: result
			});
		} catch (e) {
			this.orders.forEach(order => {
				this.analytics.paymentFailed(order, payment, 'error finishing paypal ' + e.toString());
			});
			this.loading = false;
			throw e;
		}
	}

	loadPaypalBase(paymentMethod: PaymentMethod, elementId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					this.payment = prevPayment;
					await this.loadPaypalJs(this.payment);
					// @ts-ignore
					await this.loadPaypalButton(this.payment, this.paymentMethodToPaypalFunding(paymentMethod), elementId);
					resolve();
					return;
				}
				this.payment = await Api.createPayment(this.payment);
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));

				await this.loadPaypalJs(this.payment);
				// @ts-ignore
				await this.loadPaypalButton(this.payment, this.paymentMethodToPaypalFunding(paymentMethod), elementId);
				resolve();
			} catch (e) {
				console.error('error while paypal setup');
				reject();
			}
		});
	}

	paymentMethodToPaypalFunding(paymentMethod: PaymentMethod): any {
		switch (paymentMethod) {
			case PaymentMethod.CREDIT_CARD:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.SOFORTBANKING:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.PAYPAL:
				// @ts-ignore
				return paypal.FUNDING.PAYPAL;
			default:
				return null;
		}
	}

	dismiss() {
		if (this.loading) {
			return;
		}
	}
	async loadWordline() {
		try {
			this.payment._id = undefined;
			const payment = await Api.createWorldLinePayment(paymentBody(this.payment), this.venue._id);
			if (payment?.worldlinePayment?.paymentPage) {
				window.open(payment?.worldlinePayment?.paymentPage, '_blank');
			}
		} catch (e) {
			this.snackBarCtrl.open('Error while creating payment.', null, {
				duration: 4000
			});
		}
	}
}
