import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';

import {environment} from 'src/environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {AnalyticsService} from 'src/smoothr-web-app-core/services/analytics/analytics.service';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';
import {CookieService} from 'ngx-cookie-service';
import {WorkingHoursModalComponent} from 'src/app/components/working-hours-modal/working-hours-modal.component';
import {ModalController} from '@ionic/angular';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import {Location} from '@angular/common';
import {ImpressumPage} from '../impressum/impressum.page';
import {TosPage} from '../tos/tos.page';
import {Api} from 'src/smoothr-web-app-core/api/api';
declare var cookieConsentData: any;
declare var cookieConsentCallback: any;
@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss']
})
export class PrivacyPage implements OnInit, OnDestroy {
	static url = 'privacy';
	checkIfVenueExistPrivacy = '';
	constructor(
		public repository: RepositoryService,
		private router: Router,
		private translate: TranslateService,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef,
		private cookieService: CookieService,
		private modalController: ModalController,
		private location: Location
	) {}
	ngOnInit(): void {}
	static async navigate(router: Router) {
		await router.navigateByUrl(PrivacyPage.url);
	}
	ngOnDestroy(): void {}

	ionViewDidEnter() {
		if (this.repository._venue && this.repository._order) {
			if (this.repository._venue && this.repository._venue?.legal?.privacy) {
				this.checkIfVenueExistPrivacy = this.repository._venue?.legal?.privacy;
			} else {
				this.checkIfVenueExistPrivacy = '';
			}
		} else {
			this.checkIfVenueExistPrivacy = '';
		}
		this.privacyLogic();
	}
	async privacyLogic() {
		const response = (await Api.getLegal(environment.venueId)).data;
		if (response.privacy) {
			this.checkIfVenueExistPrivacy = response.privacy;
		}
	}
	async goBack() {
		if (this.location) {
			this.location.back();
			return;
		}
		if (this.repository._order === null) {
			await HomePage.navigate(this.router);
		} else {
			await MenuPage.navigate(this.router);
		}
	}

	async editCookies() {
		try {
			await this.analytics.setAnalyticsCollectionEnabled(false);
		} catch (e) {
			console.log(e);
		}
		console.log('Test');

		this.cookieService.deleteAll();

		await this.analytics.setAnalyticsCollectionEnabled(false);

		console.log(navigator.cookieEnabled);

		const findDialogWithCookie = document.getElementsByClassName('cc_css_reboot');

		await sleep(500);
		await this.initCookieConsent();

		// window.location.reload();
	}
	private async initCookieConsent() {
		console.log(cookieConsent);
		this.cdr.detectChanges();
		const onContentLoaded = async () => {
			cookieConsent.run({
				notice_banner_type: 'simple',
				consent_type: 'express',
				palette: 'dark',
				language: 'de',
				website_name: this.translate.instant('cookie_consent.title.' + environment.customerGroup[1]),
				cookies_policy_url: this.translate.instant('cookie_consent.policy_url.' + environment.customerGroup[1])
			});
			console.log(cookieConsentData);
		};
		const a = await onContentLoaded();
		console.log(a);
		cookieConsentCallback = async () => {
			await this.handleCookieConsent();
		};
		// await this.handleCookieConsent();
	}

	async handleCookieConsent() {
		console.log('handleCookieConsent()', cookieConsentData);
		await this.setAnalyticsEnabled(cookieConsentData.targeting);
	}

	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}

	async toImpressum() {
		await ImpressumPage.navigate(this.router);
	}
	async toAGB() {
		await TosPage.navigate(this.router);
	}
}
