import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {environment} from '../../../environments/environment';

import {PrivacyPage} from '../privacy/privacy.page';
import {TosPage} from '../tos/tos.page';
import {Api} from 'src/smoothr-web-app-core/api/api';

@Component({
	selector: 'app-impressum',
	templateUrl: './impressum.page.html',
	styleUrls: ['impressum.page.scss']
})
export class ImpressumPage implements OnInit {
	static url = 'impressum';
	environment = environment;
	checkIfVenueExistImpressum = '';

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private location: Location
	) {}
	static navigate(router: Router) {
		router.navigateByUrl(ImpressumPage.url);
	}
	ionViewDidEnter() {
		console.log(this.repository._venue?.legal?.imprint);
		if (this.repository._venue && this.repository._order) {
			if (this.repository._venue && this.repository._venue?.legal?.imprint) {
				this.checkIfVenueExistImpressum = this.repository._venue?.legal?.imprint;
			} else {
				this.checkIfVenueExistImpressum = '';
			}
		} else {
			this.checkIfVenueExistImpressum = '';
		}
		this.privacyLogic();
	}
	async privacyLogic() {
		const response = (await Api.getLegal(environment.venueId)).data;
		if (response.privacy) {
			this.checkIfVenueExistImpressum = response.imprint;
		}
	}
	ngOnInit(): void {
		this.repository.venue.subscribe(v => {
			if (v && v?.legal?.imprint) {
				this.checkIfVenueExistImpressum = v?.legal?.imprint;
			} else {
				this.checkIfVenueExistImpressum = '';
			}
		});
	}

	async goBack() {
		if (this.location) {
			this.location.back();
			return;
		}
		this.location.back();
	}

	async toPrivacy() {
		await PrivacyPage.navigate(this.router);
	}
	async toAGB() {
		await TosPage.navigate(this.router);
	}
}
