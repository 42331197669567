import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Api} from '../smoothr-web-app-core/api/api';
import {MaintenancePage} from './pages/maintenance/maintenance.page';
// import { SwUpdate } from '@angular/service-worker';

declare var cookieConsentData: any;
declare var cookieConsentCallback: any;
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {catchError, filter} from 'rxjs/operators';
import {of} from 'rxjs';

import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {InformationModalComponent} from './components/information-modal/information-modal.component';
import {InfoModalComponent} from './components/info-dialog/info-modal.component';
import {SliderInfoModalComponent} from './components/slider-info-modal/slider-info-modal.component';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
	static largeScreen = false;
	static analyticsEnabled = false;
	showIos = false;
	constructor(
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private analytics: AngularFireAnalytics,
		platform: Platform,
		private translate: TranslateService,
		// private swUpdate: SwUpdate,
		private afMessaging: AngularFireMessaging,
		private repository: RepositoryService,
		private modalCtrl: ModalController
	) {
		console.log(environment.customerGroup + ' ' + environment.version);
		const favIcon: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
		favIcon.type = 'image/x-icon';
		favIcon.rel = 'shortcut icon';
		favIcon.href = '/assets/sevenbybat/logo1.png';
		document.getElementsByTagName('head')[0].appendChild(favIcon);
		document.title = translate.instant('index.title.' + environment.customerGroup);
		translate.setDefaultLang('de');
		translate.use('de');

		this.initCookieConsent();

		platform.ready().then(() => {
			AppComponent.largeScreen = platform.width() >= 992;
			platform.resize.subscribe(() => {
				AppComponent.largeScreen = platform.width() >= 992;
			});
			this.startLogicWithPushNotifications();
		});
		Api.isMaintenanceActive()
			.then(result => {
				if (result) {
					MaintenancePage.navigate(this.router);
				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	ngOnInit() {
		setTimeout(() => {
			if (window?.location?.href?.includes('home')) {
				var ua = navigator.userAgent.toLowerCase();
				if (ua.indexOf('safari') != -1) {
					if (ua.indexOf('chrome') > -1) {
					} else {
						const token = JSON.parse(localStorage.getItem('deviceData'));
						this.showIos = !token;
					}
				}
			}
		}, 1000);
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((val: any) => {
			this.showIos = false;

			if (val && val?.url?.includes('home')) {
				var ua = navigator.userAgent.toLowerCase();
				if (ua.indexOf('safari') != -1) {
					if (ua.indexOf('chrome') > -1) {
					} else {
						const token = JSON.parse(localStorage.getItem('deviceData'));
						this.showIos = !token;
					}
				}
			}
		});
	}
	async showMessage(data: {title: string; info: string}) {
		await InfoModalComponent.show(this.modalCtrl, data);
	}

	async handleCookieConsent() {
		console.log('handleCookieConsent()');
		AppComponent.analyticsEnabled = cookieConsentData.targeting;
		await this.setAnalyticsEnabled(cookieConsentData.targeting);
	}

	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}

	private async initCookieConsent() {
		const onContentLoaded = async () => {
			cookieConsent.run({
				notice_banner_type: 'simple',
				consent_type: 'express',
				palette: 'dark',
				language: 'en',
				website_name: this.translate.instant('cookie_consent.title.' + environment.customerGroup),
				cookies_policy_url: this.translate.instant('cookie_consent.policy_url.' + environment.customerGroup)
			});
		};
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', onContentLoaded);
		} else {
			await onContentLoaded();
		}
		cookieConsentCallback = async () => {
			console.log(cookieConsentData);
			await this.handleCookieConsent();
		};
		await this.handleCookieConsent();
	}

	showNotification() {
		console.log(window);
		this.isIosLogic(true);
	}
	async subscribeToNotifications() {
		try {
			console.log(Notification.name);
			this.afMessaging.requestToken.subscribe(v => {
				this.sendDeviceData(v);
			});
		} catch (e) {
			console.log('reguestTokenDoesn Work', e);
		}
	}
	startLogicWithPushNotifications() {
		var ua = navigator.userAgent.toLowerCase();
		if (ua.indexOf('safari') != -1) {
			if (ua.indexOf('chrome') > -1) {
				this.isChrome();
			} else {
				this.isIosLogic();
			}
		}
	}
	async isIosLogic(showDialog: boolean = false) {
		// if ('serviceWorker' in navigator && 'PushManager' in window) {
		// 	navigator.serviceWorker
		// 		.register('/ngsw-worker.js')
		// 		.then(registration => {
		// 			console.log(registration);
		// 			//   this.afMessaging.messaging.subscribe(
		// 			// 	(_messaging) => {
		// 			// 	  _messaging.useServiceWorker(registration);
		// 			// 	}
		// 			//   );
		// 		})
		// 		.catch(error => {
		// 			console.error('Service Worker Error', error);
		// 		});
		// }
		if (!('Notification' in window)) {
			if (showDialog) {
				await SliderInfoModalComponent.show(this.modalCtrl);
				return;
			}
			if (!JSON.parse(localStorage.getItem('showDialog'))) {
				await SliderInfoModalComponent.show(this.modalCtrl);
				localStorage.setItem('showDialog', JSON.stringify(true));
			}
			// alert('This browser does not support push notification');
			return;
		}

		// Check if the user has granted permission
		console.log(Notification.permission);
		if (Notification.permission === 'granted') {
			this.subscribeToNotifications();
			this.listenMessages();
		} else if (Notification.permission !== 'denied') {
			Notification.requestPermission()
				.then(permission => {
					if (permission === 'granted') {
						this.subscribeToNotifications();
						this.listenMessages();
					}
				})
				.catch(error => {
					console.error('Notification permission request failed:', error);
				});
		}

		// this.afMessaging.messages.subscribe(message => {
		// 	console.log('MESSAGE', message);
		// 	if (message) {
		// 		this.showMessage({ title: message.notification.title, info: message.notification.body });
		// 	}
		// });
	}
	listenMessages() {
		try {
			console.log('listen message');
			this.afMessaging.messages.subscribe(message => {
				console.log('MESSAGE', message);

				if (message) {
					this.repository.isReadyOrder.next(true);
					let info = message.notification.body;
					const getCode = this.getCode();
					if (getCode && info) {
						info = info.replace('{code}', getCode);
					}
					this.showMessage({title: message.notification.title, info: info});
				}
			});
		} catch (e) {
			console.log(e);
		}
	}
	getCode() {
		try {
			const value = JSON.parse(localStorage.getItem('lastOrderCode'));
			if (value) {
				return value;
			} else {
				return '';
			}
		} catch (e) {
			return '';
		}
	}
	isChrome() {
		this.listenMessages();

		this.afMessaging.requestPermission.subscribe(
			async () => {
				console.log('Permission granted!');
				const token = await this.afMessaging.requestToken.subscribe(token => {
					console.log('TOKEN', token);
					this.sendDeviceData(token);
				});
				// ({ vapidKey: environment.firebaseConfig.vapidKey });
				if (token) {
					this.listenMessages();
				}
			},
			error => {
				console.log('Permission denied:', error);
			}
		);
	}
	async sendDeviceData(token: string) {
		try {
			console.log(token);
			if (!token) {
				return;
			}
			//TODO: add model
			token = token;
			// alert(token);
			const deviceDataFromStorage = JSON.parse(localStorage.getItem('deviceData'));
			let deviceResponse: any;
			if (deviceDataFromStorage && deviceDataFromStorage.token === token && token && deviceDataFromStorage.token) {
				this.showIos = false;

				return;
			}
			if (deviceDataFromStorage && deviceDataFromStorage.token != token) {
				deviceResponse = await Api.updateDevice(
					{
						token: token
					},
					deviceDataFromStorage._id
				);
			} else {
				deviceResponse = await Api.createDeviceWithToken({
					customerGroup: environment.customerGroup,
					token: token,
					customer: this.repository?._customerAuth?.customer?._id ?? ''
				});
			}

			if (deviceResponse) {
				localStorage.setItem('deviceData', JSON.stringify(deviceResponse));
				this.showIos = false;
			}
		} catch (e) {
			console.log('Problems with getting token');
			localStorage.setItem('deviceData', null);
		}
	}
}
