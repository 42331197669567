import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import validator from 'validator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AccountPage} from '../account/account.page';
import {EmailConfirmationPage} from '../email-confirmation/email-confirmation.page';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {environment} from '../../../environments/environment';
import {Gender} from 'src/smoothr-web-app-core/enums/Gender';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {SignInPage} from '../sign-in/sign-in.page';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {AuthStrategy} from '../../../smoothr-web-app-core/models/AuthStrategy';
import {axiosErrorToMessage} from '../../../smoothr-web-app-core/utils/utils';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {TosPage} from '../tos/tos.page';
import {PrivacyPage} from '../privacy/privacy.page';
import {ImpressumPage} from '../impressum/impressum.page';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.page.html',
	styleUrls: ['sign-up.page.scss']
})
export class SignUpPage implements OnDestroy {
	static url = 'sign-up';

	showRePassword = false;
	showPassword = false;
	isValidEmail = true;
	isValidName = true;
	isValidPassword = true;
	isValidRePassword = true;
	venue: Venue;
	email = '';
	password = '';
	rePassword = '';
	name = '';
	surName = '';
	firma = '';
	agbChecked = false;
	loading = false;
	isValid = false;
	Gender = Gender;
	gender: Gender = Gender.male;
	certificateChecked = false;

	private readonly subscription: Subscription;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar
	) {
		if (repository.customer && !repository.customer.isAnonymous) {
			AccountPage.navigate(router);
		}
	}

	static navigate(router: Router) {
		router.navigateByUrl(SignUpPage.url);
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	validate() {
		if (this.gender === null) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.gender'), null, {
				duration: 5000
			});
			return;
		}

		if (!this.name.trim().length || !this.surName.trim().length) {
			this.isValid = false;
			this.isValidName = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.name'), null, {
				duration: 5000
			});
			return;
		} else {
			this.isValidName = true;
		}

		// if (!validator.isAlpha(this.name.replace(/\s/g, ''))) {
		// 	this.isValid = false;
		// 	this.isValidName = false;
		// 	this.snackbarCtrl.open(this.translate.instant('sign_up.validation.name_only_letter'), null, {
		// 		duration: 5000
		// 	});
		// 	return;
		// } else {
		// 	this.isValidName = true;
		// }
		if (
			this.email &&
			!(
				this.email.includes('bat.com') ||
				this.email.includes('tamoil.de') ||
				this.email.includes('lehvoss.de') ||
				this.email.includes('smoothr.de') ||
				this.email.includes('kitchenguerilla.com')
			)
		) {
			this.isValid = false;
			this.isValidEmail = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.not_company_email'), null, {
				duration: 5000
			});
			return;
		}
		if (!validator.isEmail(this.email)) {
			this.isValid = false;
			this.isValidEmail = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.email'), null, {
				duration: 5000
			});
			return;
		} else {
			this.isValidEmail = true;
		}

		if (!ValidationUtils.validatePassword(this.password)) {
			this.isValid = false;
			this.isValidPassword = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.password'), null, {
				duration: 6000
			});
			return;
		} else {
			this.isValidPassword = true;
		}
		if (this.password !== this.rePassword) {
			this.isValid = false;
			this.isValidRePassword = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.re_password'), null, {
				duration: 5000
			});
			return;
		} else {
			this.isValidRePassword = true;
		}
		if (this.repository.customer === null) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.user'), null, {
				duration: 5000
			});
			return;
		}
		if (!this.repository.customer.isAnonymous) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.already_signed_in'), null, {
				duration: 5000
			});
			return;
		}
		if (!this.agbChecked) {
			this.snackbarCtrl.open(this.translate.instant('errors.privacy_and_policy'), null, {
				duration: 6000
			});
			return false;
		}

		this.isValid = true;
		this.isValidEmail = true;
	}

	async signUp() {
		this.validate();
		if (this.isValid && !this.loading) {
			this.loading = true;
			try {
				const data = {
					name: this.name + ' ' + this.surName,
					gender: this.gender,
					email: this.email,
					preferredVenues: [],
					birthDate: '',
					subscribedToMailing: false
				};
				await Api.signUpWithCredentials(data, this.password);
				await EmailConfirmationPage.navigate(this.router, true);
			} catch (e) {
				if (e.response.data.name === 'UserAlreadyExistsError') {
					this.isValidEmail = false;
				}
				this.snackbarCtrl.open(axiosErrorToMessage(this.translate, e));
			}
			this.loading = false;
		}
	}

	signIn() {
		SignInPage.navigate(this.router);
	}

	setEmailValid() {
		this.isValidEmail = true;
	}

	setNameValid() {
		this.isValidName = true;
	}

	setPasswordValid() {
		this.isValidPassword = true;
		this.isValidRePassword = true;
	}

	async signUpWithGoogle() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Google');
				const result = await Api.signIn(AuthStrategy.GOOGLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithFacebook() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Facebook');
				const result = await Api.signIn(AuthStrategy.FACEBOOK);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithApple() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Apple');
				const result = await Api.signIn(AuthStrategy.APPLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async openTos() {
		console.log('TOS');
		window.open(TosPage.url, '_blank');
	}
	async openPrivacy() {
		console.log('HRE');
		window.open(PrivacyPage.url, '_blank');
	}
	async openImpressum() {
		window.open(ImpressumPage.url, '_blank');
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
