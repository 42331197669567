import {Component, Input} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {OrderUtils, getArticlePriceByUser} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from 'src/environments/environment';
import {
	defaultsToArticleOption,
	getBasePrice,
	getPrice,
	numberD,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import {ValidationUtils} from 'src/smoothr-web-app-core/utils/validation-utils';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import Customer from 'src/smoothr-web-app-core/models/Customer';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss']
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	priceText = '';
	numberToCurrency = numberToCurrency;
	fromPrice = false;
	veganValue: string = '';

	constructor() {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
		this.veganValue = this.checkVeganTag(value);
	}
	@Input()
	set userData(value: Customer) {
		this.customer = value;
	}
	customer: Customer;
	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
	}

	private minOfItem<T>(items: T[], transformer: (item: T) => number): T {
		if (items.length === 1) {
			return items[0];
		}
		const values = items.map(item => transformer(item));
		const min = Math.min(...values);
		const index = values.indexOf(min);
		return items[index];
	}
	private reloadPrice() {
		this.fromPrice = false;
		const articleGroup = new ArticleGroup();
		articleGroup.article = this.article;
		articleGroup.groups = defaultsToArticleOption(this.article, [], [], this.preorderType);

		articleGroup.quantity = 1;

		const article = JSON.parse(JSON.stringify(articleGroup.article)) as Article;
		let price = numberD(article.price);
		if (environment.getPriceByUser) {
			let userPriceLevel = numberD(getArticlePriceByUser(article, this.customer?.email));
			if (userPriceLevel > 0) {
				price = userPriceLevel;
			}
		}
		const processedGroups: string[] = [];
		if (
			article.groups.length > 0 &&
			article.groups.find(it => it.requiredAmount > 0 && it.visible && it.articles.length > 0)
		) {
			this.fromPrice = true;
		}

		article.groups.forEach(grp => {
			if (processedGroups.indexOf(grp._id) >= 0) return;
			if (ValidationUtils.isGroupDependencyFulfilled(articleGroup.article, articleGroup.groups, grp).times < 0) return;
			if (grp.requiredAmount == grp.limit) {
				const minimumItem = this.minOfItem(grp.articles, opt => {
					let optPrice = numberD(opt.price);
					if (environment.getPriceByUser) {
						optPrice = numberD(getArticlePriceByUser(article, this.customer?.email));
					}
					return optPrice;
				});
				price += numberD(minimumItem.price) * grp.requiredAmount;
				const dependants = this.dependenciesRegardingOptionArticle(article, article.groups, minimumItem);
				dependants.forEach(dependant => {
					const dependantMinimumItem = this.minOfItem(dependant.articles, opt => numberD(opt.price));
					price += grp.requiredAmount * dependant.requiredAmount * numberD(dependantMinimumItem.price);
					processedGroups.push(dependant._id);
				});
				processedGroups.push(grp._id);
			}
		});
		const tagPrice = this.findTagPrice(this.article);
		if (tagPrice && tagPrice > 0) {
			price = price - tagPrice;
		}
		this.price = price;
		this.priceText = numberToCurrency(this.price, this.currency);
		return;
	}
	dependenciesRegardingOptionArticle(article: Article, groups: OptionGroup[], optionArticle: Article): OptionGroup[] {
		return article.groupDependencies
			.map(gd => {
				if (gd.dependencies.find(dependeci => dependeci.groupArticles.includes(optionArticle._id)) == null) {
					return null;
				} else {
					return groups.find(it => gd.group === it._id);
				}
			})
			.filter(it => !!it);
	}

	isFromPrice(article: Article) {
		return !!article.tags.find(tag => tag.identifier === 'fromPrice');
	}

	getArticlePrice(article: Article, price: number) {
		let totalPrice = price;
		console.log(article.name.de, totalPrice);

		const tagPrice = this.findTagPrice(this.article);
		if (tagPrice && tagPrice > 0) {
			totalPrice = totalPrice - tagPrice;
		}
		return totalPrice;
	}
	findTagPrice(article: Article) {
		if (article && article.tags && article.tags.length > 0) {
			const foundTag = article?.tags?.find(
				it => it?.identifier === 'deposit_25' || it?.identifier === 'deposit_15' || it?.identifier === 'deposit_8'
			);
			if (foundTag) {
				return foundTag.identifier === 'deposit_25'
					? 0.25
					: foundTag.identifier === 'deposit_15'
					? 0.15
					: foundTag.identifier === 'deposit_8'
					? 0.8
					: 0;
			}
		} else {
			return null;
		}
	}

	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit_25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit_15') ? 'deposit15' : '';
		const deposit8 = article.tags.find(it => it.identifier === 'deposit_8') ? 'deposit8' : '';

		return deposit15 || deposit25 || deposit8;
	}

	showMeasurement(article: Article) {
		if (article) {
			if (article?.measurement?.refAmount && article?.measurement?.refUnit) {
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'g') {
					return (
						'1' +
						' ' +
						'kg' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency)
					);
				}
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'ml') {
					return (
						'1' +
						' ' +
						'l' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency)
					);
				}
				return (
					article.measurement.refAmount +
					' ' +
					article.measurement.refUnit +
					' = ' +
					numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType), this.currency)
				);
			}
		} else {
			return '';
		}
	}

	getArticleTags() {
		return Object.entries(this.article?.compound?.features || {})
			.filter(value => value[1] === true)
			.map(it => it[0]);
	}
	checkVeganTag(article: Article) {
		if (!article?.compound?.features) {
			return '';
		}
		if (article.compound?.features?.vegetarian) {
			return 'vegetarian';
		}
		if (article.compound?.features?.vegan) {
			return 'vegan';
		}
		return '';
	}
}
